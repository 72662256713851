import { CSSProperties, FC } from 'react';
import { defineMessages, useIntl } from 'dibs-react-intl';
import classnames from 'classnames';
import { useFragment, graphql } from 'react-relay';

import { OriginalListPrice_item$key } from './__generated__/OriginalListPrice_item.graphql';

import styles from './styles.scss';
import dibsCss from 'dibs-css';
import { useOriginalListPrice } from '../../hooks/useOriginalListPrice';

export const PUB_TOOLS_KEY = 'pubTools';
const SEARCH_BROWSE_KEY = 'searchBrowse';
const EMAIL_KEY = 'email';
const RAW_PRICE_KEY = 'rawPrice';
export const ADMIN_IIM_KEY = 'adminIIM';
export const BIDDING_MODAL_KEY = 'biddingModal';
export const AUCTION_SALE_TREATMENT_VARIANT_KEY = 'auctionSaleTreatmentVariant';

const message = defineMessages({
    [SEARCH_BROWSE_KEY]: {
        id: 'dibs-search-product-tile.Price.OriginalListPrice.searchBrowse',
        defaultMessage: `{isCompact, select,
            true {{price} Orig. List}
            other {{price} Original List Price}
        }`,
    },
    [EMAIL_KEY]: {
        id: 'dibs-search-product-tile.Price.OriginalListPrice.email',
        defaultMessage: 'Original List Price: {price}',
    },
    [PUB_TOOLS_KEY]: {
        id: 'dibs-search-product-tile.Price.OriginalListPrice.pubTools',
        defaultMessage: 'Orig. List Price: {price}',
    },
    [ADMIN_IIM_KEY]: {
        id: 'dibs-search-product-tile.Price.OriginalListPrice.adminIIM',
        defaultMessage: '<b>Orig. List Price:</b> <dataTnWrapper>{price}</dataTnWrapper>',
    },
    [RAW_PRICE_KEY]: {
        id: 'dibs-search-product-tile.Price.OriginalListPrice.rawPrice',
        defaultMessage: '{price}',
    },
    [BIDDING_MODAL_KEY]: {
        id: 'dibs-search-product-tile.Price.OriginalListPrice.bidingModal',
        defaultMessage: '{price} Original List Price',
    },
    [AUCTION_SALE_TREATMENT_VARIANT_KEY]: {
        id: 'dibs-auctions.Price.OriginalPrice.auctionSaleTreatmentVariant',
        defaultMessage: '{price} Original Price',
    },
});

const itemFragment = graphql`
    fragment OriginalListPrice_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: pdp }) {
        ...useOriginalListPrice_item @arguments(page: $page)
    }
`;

export type OriginalListPriceProps = {
    currency: string;
    item: OriginalListPrice_item$key | null | undefined;
    isCompact?: boolean;
    className?: string;
    type?:
        | typeof SEARCH_BROWSE_KEY
        | typeof EMAIL_KEY
        | typeof PUB_TOOLS_KEY
        | typeof RAW_PRICE_KEY
        | typeof ADMIN_IIM_KEY
        | typeof BIDDING_MODAL_KEY
        | typeof AUCTION_SALE_TREATMENT_VARIANT_KEY;
    style?: CSSProperties;
    reducedPrice?: boolean;
    includeSpacer?: boolean;
};

const OriginalListPrice: FC<OriginalListPriceProps> = ({
    currency,
    item: itemRef,
    isCompact,
    className,
    type = SEARCH_BROWSE_KEY,
    style,
    reducedPrice = false,
    includeSpacer = false,
}) => {
    const intl = useIntl();
    const item = useFragment(itemFragment, itemRef);
    const { originalListPrice } = useOriginalListPrice({ item, currency });

    if (includeSpacer && type !== AUCTION_SALE_TREATMENT_VARIANT_KEY) {
        throw new Error('Spacer is only allowed for AUCTION_SALE_TREATMENT_VARIANT_KEY');
    }

    if (!originalListPrice) {
        return null;
    }

    const messageToFormat = message[type];
    const priceIntl = intl.formatNumber(originalListPrice, {
        currency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return (
        <div
            className={classnames(styles.originalListPrice, className)}
            style={style}
            data-tn="original-list-price"
        >
            {intl.formatMessage(messageToFormat, {
                price: (
                    <span
                        key={priceIntl}
                        className={classnames({ [styles.reducedPrice]: reducedPrice })}
                    >
                        {priceIntl}
                    </span>
                ),
                isCompact,
                b: content => <b>{content}</b>,
                dataTnWrapper: content => (
                    <span data-tn="original-list-price-value">{content}</span>
                ),
            })}
            {includeSpacer && (
                <span
                    className={classnames(
                        styles.spacer,
                        dibsCss.borderL,
                        dibsCss.borderLDolphin,
                        dibsCss.borderLSolid,
                        dibsCss.mxXsmall,
                        dibsCss.alignMiddle
                    )}
                />
            )}
        </div>
    );
};

export default OriginalListPrice;
